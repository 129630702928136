// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  baseUrl: 'https://app.api.herzbegleiter.de/',
  websocket:
    'wss://f4kkv1ff8h.execute-api.eu-central-1.amazonaws.com/production',
  cloudfront: 'https://d3hcjmr9o9c4gl.cloudfront.net',
  stripePublicKey:
    'pk_live_51J8hJ1HOUefBe0xwhsrQWJQoY9PRvXK8ft50XN9aWixO1TMcbP5OCY1jfk2MB1PIOFxJ6YbxSC1KOlK6hHhuSyDk00GQHjmGHi',
  stripeAccount: 'acct_1J8hJ1HOUefBe0xw',
  stripeProductId: 'prod_K6lgwzVum5qXji',
  stripePriceId: 'price_1JSY9THOUefBe0xwNyZ8yq6Y',
  stripeReturnUrl: 'https://app.herzbegleiter.de/mobility',
  googleId:
    '968731604805-69e8blkcr0v12lb84vi1agat81bi181s.apps.googleusercontent.com',
  googleApiKey: 'AIzaSyCLat_qEFJ-OQ4bOYGd-ACwP_ztmXPeb2A',
  firebaseApiKey: 'AIzaSyBnZOWju_lJ3hHmcfp8wDs0NlFToIlrqdk',
  firebaseAuthDomain: 'herzbegleiter-live.firebaseapp.com',
  firebaseProjectId: 'herzbegleiter-live',
  firebaseStorageBucket: 'herzbegleiter-live.appspot.com',
  firebaseSenderId: '968731604805',
  firebaseAppId: '1:968731604805:web:60c07d05f72d499060302c',
  firebaseMeasurementId: 'G-WX20D623M0',
  hiddenVersion: 1,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
